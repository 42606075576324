<template>
  <div class="mb-15">
    <banner title="Featured Sermon" />
    <weekly-message
      ref="topOfMessage"
      :message="currentMessage"
      :selectedMessage="currentMessageIsChosen ? currentMessage : selectedMessage"
    />
    <div class="mb-8" v-if="allTitles.length">
      <v-row justify="center">
        <v-col align-self="center" align="center">
          <v-btn color="blue lighten-1" dark v-if="ifCheck" @click="goToCurrent"
            >Back To Current Message</v-btn
          >
        </v-col>
      </v-row>
      <v-divider class="my-8"></v-divider>
      <v-row align="center">
        <v-col class="mx-auto">
          <h3 class="text-center">
            <u>Listen to past weekly messages</u>
          </h3>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col md="6" sm="9" class="mx-auto">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-row justify="center">
                <v-col
                  align="center"
                  v-for="item in allTitles"
                  :key="item.title"
                  :cols="$vuetify.breakpoint.xs ? '12' : '6'"
                >
                  <v-btn
                    x-large
                    text
                    dark
                    color="blue darken-3"
                    v-on="on"
                    v-bind="attrs"
                    @click="showAvailableSermons(item)"
                    >{{ item.author }}</v-btn
                  >
                </v-col>
              </v-row>
            </template>
            <v-card dark color="blue lighten-1">
              <v-card-title style="overflow-wrap: break-word"
                >Available Sermons by &nbsp; <span>{{ author }}</span>
              </v-card-title>
              <v-list color="blue darken-3" tile shaped>
                <template>
                  <div v-for="(title, index) in availableTitles" :key="index">
                    <v-hover v-slot="{ hover }">
                      <v-list-item
                        :style="hover ? 'font-weight:bold' : ''"
                        class="ma-2"
                        @click="getSelectedMessage(title)"
                        color="primary"
                      >
                        <v-list-item-icon
                          v-if="$vuetify.breakpoint.smAndUp"
                          :class="hover ? '' : 'hovered-item'"
                        >
                          <v-icon>mdi-play-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ title }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-hover>
                    <v-divider inset></v-divider>
                  </div>
                </template>
              </v-list>
              <v-card-actions class="mr-auto">
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">
                  <v-icon>mdi-close</v-icon>Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import WeeklyMessage from "../components/weeklySermon/WeeklyMessage.vue";

export default {
  name: "Message",
  components: { WeeklyMessage, Banner },
  data: () => ({
    firstVisit: true,
    model: 1,
    dialog: false,
    availableTitles: [],
    author: "",
  }),
  metaInfo() {
    return {
      title: "Featured Sermon",
      link: [{ rel: "canonical" }],
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Come back each week to listen to another featured sermon here at GraceWay Radio.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Come back each week to listen to another featured sermon here at GraceWay Radio.",
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Featured Sermons" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/weekly-message",
        },
      ],
    };
  },
  // watch: {
  //   selectedMessage(message) {
  //     this.messageUrl(message.slug);
  //   },
  // },
  methods: {
    showAvailableSermons(item) {
      this.author = item.author;
      const filtered = this.allTitles
        .filter((i) => i.author === item.author)
        .map((obj) => obj.titles);
      this.availableTitles = filtered.flat();
    },
    goToCurrent() {
      if (this.$route?.params?.messageSlug === this.currentMessage?.slug) return;
      this.$router.push({
        name: "WeeklyMessage",
        params: { messageSlug: this.currentMessage.slug },
      });
    },
    getMessage() {
      const slug = this.$route?.params?.messageSlug || null;
      if (slug) this.$store.dispatch("audio/getSelectedMessage", slug);
      this.$store.dispatch("audio/getCurrentMessage");
    },
    getSelectedMessage(slug) {
      // const element = this.$refs['topOfMessage'].$el;
      if (this.$route.params?.messageSlug === slug) return;
      this.$store
        .dispatch("audio/getSelectedMessage", slug)
        .then(
          () => (this.dialog = false),
          this.$vuetify.goTo(this.$refs["topOfMessage"].$el),
          this.$router.push({ name: "WeeklyMessage", params: { messageSlug: slug } })
        );
    },
    getAllTitles() {
      this.$store.dispatch("audio/getAllMessageTitles");
    },
  },
  computed: {
    currentMessage() {
      return this.$store.state.audio.weeklyMessage;
    },
    selectedMessage() {
      return this.$store.state.audio.selectedMessage;
    },
    allTitles() {
      const usable = this.$store.state.audio.allMessageTitles.toSorted((a, b) =>
        a.author !== b.author ? (a.author < b.author ? -1 : 1) : 0
      );
      return Object.values(
        usable.reduce((acc, { author, title }) => {
          acc[author] = acc[author] || { author, titles: [] };
          acc[author].titles.push(title);
          return acc;
        }, {})
      );
    },
    ifCheck() {
      return (
        !this.currentMessageIsChosen &&
        !!this.selectedMessage?.title &&
        this.currentMessage?.title !== this.selectedMessage.title
      );
    },
    currentMessageIsChosen() {
      return (
        this.$route?.params?.messageSlug === this.currentMessage?.slug ||
        !this.$route?.params?.messageSlug
      );
    },
  },
  mounted() {
    this.getMessage();
    this.getAllTitles();
  },
};
</script>

<style lang="scss" scoped>
.hovered-item {
  opacity: 0;
  transition: 0.8s;

  &::hover {
    opacity: 1;
  }
}

.regular {
  opacity: 1;
}
</style>
